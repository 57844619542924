/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as AppResourceStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class AppResourceState implements AppResourceStateModule.AppResourceState {
        static objectStoreDexieSchema: string = "&[appId+id+scope], appId";

        appId!: string;
        id!: string;
        scope!: string;
        relativeRoot!: string;
        url!: string;

        get primaryKey(): string {
            return `${this.appId} - ${this.id} - ${this.scope}`;
        }

        constructor(appId: string, id: string, scope: string, relativeRoot: string, url: string) {
            this.appId = appId;
            this.id = id;
            this.scope = scope;
            this.relativeRoot = relativeRoot;
            this.url = url;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateAppResourceState(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.deleteAppResourceState(this);
        }

        public async forceReload(): Promise<AppResourceState | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            return await IndexedDBHandler.getAppResourceStateFromIndexedDB(this.appId, this.id);
        }
    }

    self.o365.exportScripts({ AppResourceState });
})();